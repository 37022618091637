/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpMoneyGramAppStates($stateProvider: any) {
  'ngInject';

  $stateProvider
    .state('money-gram', {
      parent: 'root',
      redirectTo: '.landing',
      url: '/money-gram',
      templateUrl: 'apps/money-gram/templates/acp-money-gram.ng.html',
      resolve: {
        module($ocLazyLoad) {
          'ngInject';

          return import(/* webpackChunkName: "acp.apps.money-gram" */ './index').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('money-gram.landing', {
      template: `<acp-money-gram-landing-component></acp-money-gram-landing-component>`,
      data: {
        permissions: {
          only: 'isSecurityVerified',
          redirectTo: {
            isSecurityVerified: 'money-gram.ooba-questions-check'
          }
        }
      }
    })
    .state('money-gram.ooba-questions-check', {
      template: `<acp-money-gram-ooba-question-check-component></acp-money-gram-ooba-question-check-component>`
    })
    .state('money-gram.search-result', {
      template: `<acp-money-gram-search-result-component></acp-money-gram-search-result-component>`
    })
    .state('money-gram.create-payment', {
      template: `<acp-money-gram-create-payment-component></acp-money-gram-create-payment-component>`
    })
    .state('money-gram.confirm-payment', {
      template: `<acp-money-gram-confirm-payment-component></acp-money-gram-confirm-payment-component>`
    })
    .state('money-gram.receipt', {
      template: `<acp-money-gram-billing-receipt-component></acp-money-gram-billing-receipt-component>`,
      params: {
        ackResponseSuccessFlag: true
      }
    });
}
